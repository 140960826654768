define('ember-load/components/ember-load-remover', ['exports', 'ember', 'ember-load/templates/components/ember-load-remover'], function (exports, _ember, _emberLoadTemplatesComponentsEmberLoadRemover) {
  'use strict';

  var inject = _ember['default'].inject;
  var Component = _ember['default'].Component;
  var $ = _ember['default'].$;

  exports['default'] = Component.extend({
    layout: _emberLoadTemplatesComponentsEmberLoadRemover['default'],
    'ember-load-config': inject.service(),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.removeLoadingIndicator();
    },
    /**
     * remove the loading indicator. By default this
     * removes the first element with the '.ember-load-indicator'
     * found CSS class from the DOM
     * @public
     */
    removeLoadingIndicator: function removeLoadingIndicator() {
      var loadingIndicatorClass = this.get('ember-load-config.loadingIndicatorClass') || 'ember-load-indicator';
      $('.' + loadingIndicatorClass).remove();
    }
  });
});