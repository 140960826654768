define('ember-x-editable/components/x-editable-textarea/component', ['exports', 'ember-x-editable/components/x-base/component', 'ember-x-editable/components/x-editable-textarea/template'], function (exports, _emberXEditableComponentsXBaseComponent, _emberXEditableComponentsXEditableTextareaTemplate) {
  'use strict';

  exports['default'] = _emberXEditableComponentsXBaseComponent['default'].extend({
    layout: _emberXEditableComponentsXEditableTextareaTemplate['default'],

    /**
     * This is a computed property for adding/removing the is-empty class
     * @private
     */
    isEmpty: Ember.computed('isEditing', function () {
      if (!Ember.get(this, 'isEditing')) {
        if (!Ember.get(this, 'value') || Ember.get(this, 'value') === '' || Ember.get(this, 'value') === 'Empty') {
          return true;
        }
      }
      return false;
    }),

    changeUnderlineSize: Ember.observer('isEditing', function () {
      var _this = this;

      Ember.run.later(function () {
        if (!Ember.get(_this, 'isEditing')) {
          if (Ember.get(_this, 'value') && Ember.get(_this, 'value').length > 0) {
            var borderBottom = _this.element.querySelector('.borderBottom');
            var textarea = _this.element.querySelector('textarea');
            var textContainer = _this.element.querySelector('.textareaContainer');
            var size = _this.getTextWidth(textarea, Ember.get(_this, 'value'));
            textContainer.style.width = '68%';
            textarea.style.width = size.width + 10 + 'px';
            borderBottom.style.width = size.width + 3 + 'px';
          }
        }
      });
    }),

    makeFullWidthWhenEditing: Ember.observer('isEditing', function () {
      this.element.querySelector('textarea').style.width = '100%';
    }),

    focusOut: function focusOut() {
      var textarea = this.element.querySelector('textarea');
      textarea.style.height = '1px';
      textarea.style.height = textarea.scrollHeight + 'px';

      this._super.apply(this, arguments);
    },

    actions: {
      saveAction: function saveAction() {
        if (!Ember.get(this, 'validator')) {
          this.handleEmptyValue();
        }
        this._super.apply(this, arguments);
      }
    },

    /**
     * Set the value to the string 'Empty' when value is null, undefined, or ''.
     * @private
     */
    handleEmptyValue: function handleEmptyValue() {
      if (!Ember.get(this, 'value') || Ember.get(this, 'value') === '') {
        Ember.set(this, 'value', 'Empty');
      }
    }
  });
});