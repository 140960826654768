define("ember-rapid-forms/templates/components/html-checkbox", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 14,
            "column": 0
          }
        },
        "moduleName": "modules/ember-rapid-forms/templates/components/html-checkbox.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("input");
        dom.setAttribute(el1, "type", "checkbox");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        if (this.cachedFragment) {
          dom.repairClonedNode(element0, [], true);
        }
        var morphs = new Array(9);
        morphs[0] = dom.createAttrMorph(element0, 'checked');
        morphs[1] = dom.createAttrMorph(element0, 'name');
        morphs[2] = dom.createAttrMorph(element0, 'id');
        morphs[3] = dom.createAttrMorph(element0, 'disabled');
        morphs[4] = dom.createAttrMorph(element0, 'class');
        morphs[5] = dom.createAttrMorph(element0, 'required');
        morphs[6] = dom.createAttrMorph(element0, 'autofocus');
        morphs[7] = dom.createElementMorph(element0);
        morphs[8] = dom.createElementMorph(element0);
        return morphs;
      },
      statements: [["attribute", "checked", ["get", "checked", ["loc", [null, [6, 12], [6, 19]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "name", ["get", "mainComponent.name", ["loc", [null, [7, 9], [7, 27]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "id", ["get", "mainComponent.id", ["loc", [null, [8, 7], [8, 23]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "disabled", ["get", "mainComponent.disabled", ["loc", [null, [9, 13], [9, 35]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["get", "mainComponent.elementClass", ["loc", [null, [10, 10], [10, 36]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "required", ["get", "mainComponent.required", ["loc", [null, [11, 13], [11, 35]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "autofocus", ["get", "mainComponent.autofocus", ["loc", [null, [12, 14], [12, 37]]], 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["change"], ["on", "change"], ["loc", [null, [3, 2], [3, 33]]], 0, 0], ["element", "action", ["input"], ["on", "input"], ["loc", [null, [4, 2], [4, 31]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});