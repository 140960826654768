define('ember-x-editable/components/x-editable-select/component', ['exports', 'ember-x-editable/components/x-base/component', 'ember-x-editable/components/x-editable-select/template'], function (exports, _emberXEditableComponentsXBaseComponent, _emberXEditableComponentsXEditableSelectTemplate) {
  'use strict';

  exports['default'] = _emberXEditableComponentsXBaseComponent['default'].extend({
    layout: _emberXEditableComponentsXEditableSelectTemplate['default'],
    changeUnderlineSize: Ember.observer('isEditing', function () {
      var _this = this;

      Ember.run.later(function () {
        var borderBottom = _this.element.querySelector('.borderBottom');
        var select = _this.element.querySelector('select');
        var selectContainer = _this.element.querySelector('.selectContainer');
        var text = select.options[select.selectedIndex].text;

        if (!Ember.get(_this, 'isEditing')) {
          var size = _this.getTextWidth(select, text);
          selectContainer.style.width = 'auto';
          selectContainer.style.height = size.height + 16 + 'px';
          select.style.width = size.width + 2 + 'px';
          select.style.height = size.height + 11 + 'px';
          borderBottom.style.width = size.width;
        } else {
          selectContainer.style.width = '68%';
          selectContainer.style.height = 'auto';
          select.style.width = '100%';
        }
      });
    })
  });
});