define('ember-confirm-extension/components/confirm-extension', ['exports', 'ember', 'ember-confirm-extension/templates/components/confirm-extension'], function (exports, _ember, _emberConfirmExtensionTemplatesComponentsConfirmExtension) {
  'use strict';

  /* global $ */

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberConfirmExtensionTemplatesComponentsConfirmExtension['default'],

    tagName: 'span',
    classNames: ['ece-container'],

    _setupClickHandler: function _setupClickHandler() {
      var closer = _clickHandlerFor(this);
      this.set('_closer', closer);
      $(document).on('click', closer);
    },
    _teardownClickHandlerIfNeeded: function _teardownClickHandlerIfNeeded() {
      var closer = this.get('_closer');
      if (closer) {
        $(document).off('click', closer);
        this.set('_closer', null);
      }
    },
    _autoCloser: _ember['default'].observer('confirmMode', function () {
      if (this.get('confirmMode')) {
        this._setupClickHandler();
      } else {
        this._teardownClickHandlerIfNeeded();
      }
    }),
    _beforeRemove: _ember['default'].on('willDestroyElement', function () {
      this._teardownClickHandlerIfNeeded();
    }),
    _afterInsert: _ember['default'].on('didInsertElement', function () {
      if (this.get('confirmMode')) {
        this._setupClickHandler();
      }
    }),

    actions: {
      click: function click() {
        this.toggleProperty('confirmMode');
      },
      confirm: function confirm() {
        this.set('confirmMode', false);
        this.sendAction('confirmAction');
      },
      decline: function decline() {
        this.set('confirmMode', false);
      }
    }
  });

  function _contains(ancestor, child) {
    return ancestor.has(child).length !== 0;
  }

  function _clickHandlerFor(self) {
    return function (e) {
      if (!_contains(self.$(), e.target)) {
        _ember['default'].run(self, 'set', 'confirmMode', false);
      }
    };
  }
});