define('ember-text-mask/components/masked-input', ['exports', 'ember', 'ember-text-mask/createTextMaskInputElement'], function (exports, _ember, _emberTextMaskCreateTextMaskInputElement) {
  'use strict';

  var computed = _ember['default'].computed;
  var get = _ember['default'].get;
  var getProperties = _ember['default'].getProperties;
  var on = _ember['default'].on;
  var TextField = _ember['default'].TextField;

  function _config() {
    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return computed.apply(undefined, args.concat([function () {
      return getProperties.apply(undefined, [this].concat(args));
    }]));
  }

  /*
  
    ## MaskedInputComponent
  
    Add the following markup to your template to render a masked input component.
  
    ```hbs
    {{masked-input mask=mask}}
    ```
  
    In the template's controller, specify a `mask`.
  
    ```js
    import Ember from 'ember';
  
    export default Ember.Controller.extend({
  
      mask: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]
  
    });
    ```
  */
  exports['default'] = TextField.extend({

    mask: [],

    /*
      ## config {Object}
       This is a computed property and will re-compute when any of the dependent properties
      update.  By default it will read the properties off the component root, you
      can pass in attrbutes to the component through the template.
       ```hbs
      {{masked-input
        mask=customMask
        guide=true}}
      ```
    */
    config: _config('mask', 'guide', 'placeholderChar', 'keepCharPositions', 'pipe'),

    /*
      ## textMaskInputElement {Object}
       `textMaskInputElement` is the object that is returned from calling the
      `createTextMaskInputElement`. method.
       This is a computed property and will re-compute whenever the `config` property
      changes.
    */
    textMaskInputElement: computed('config', function () {
      var config = get(this, 'config');
      config.inputElement = this.element;
      return this.createTextMaskInputElement(config);
    }),

    createTextMaskInputElement: _emberTextMaskCreateTextMaskInputElement['default'],

    update: function update() {
      var _get;

      (_get = this.get('textMaskInputElement')).update.apply(_get, arguments);
    },

    _input: on('input', 'didRender', function () {
      this.update();
    })
  });
});