define('ember-query-params-reset/mixins/query-params-reset-route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  var Mixin = _ember['default'].Mixin;

  exports['default'] = Mixin.create({
    resetController: function resetController(controller, isExiting) {
      if (isExiting) {
        this.get('_qp.qps').forEach(function (qp) {
          var defaultValue = undefined;

          if (qp.hasOwnProperty('def')) {
            // < v2.0
            defaultValue = qp.def;
          } else {
            // >= v2.0
            defaultValue = qp.defaultValue;
          }

          controller.set(qp.prop, defaultValue);
        });
      }

      this._super.apply(this, arguments);
    }
  });
});