$.niftyNoty = function(o) {
    var t, i, e = {};
    var a = !1;
    var n = $;
    {
        var f, s = {
                type: "primary",
                icon: "",
                title: "",
                message: "",
                closeBtn: !0,
                container: "page",
                floating: {
                    position: "top-right",
                    animationIn: "jellyIn",
                    animationOut: "fadeOut"
                },
                html: null,
                focus: !0,
                timer: 0,
                onShow: function() {},
                onShown: function() {},
                onHide: function() {},
                onHidden: function() {}
            },
            l = n.extend({}, s, o),
            r = n('<div class="alert-wrap"></div>'),
            c = function() {
                var n = "";
                return o && o.icon && (n = '<div class="media-left"><span class="icon-wrap icon-wrap-xs icon-circle alert-icon"><i class="' + l.icon + '"></i></span></div>'), n
            },
            d = function() {
                var n = l.closeBtn ? '<button class="close" type="button"><i class="fa fa-times-circle"></i></button>' : "",
                    t = '<div class="alert alert-' + l.type + '" role="alert">' + n + '<div class="media">';
                return l.html ? t + l.html + "</div></div>" : t + c() + '<div class="media-body"><h4 class="alert-title">' + l.title + '</h4><p class="alert-message">' + l.message + "</p></div></div>"
            }(),
            h = function() {
                return l.onHide(), "floating" === l.container && l.floating.animationOut && (r.removeClass(l.floating.animationIn).addClass(l.floating.animationOut), nifty.transition || (l.onHidden(), r.remove())), r.removeClass("in").on("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd", function(n) {
                    "max-height" == n.originalEvent.propertyName && (l.onHidden(), r.remove())
                }), clearInterval(f), null
            },
            u = function(n) {
                $('body, html').animate({
                    scrollTop: n
                }, 300, function() {
                    r.addClass("in")
                })
            };
        ! function() {
            if (l.onShow(), "page" === l.container) t || (t = n('<div id="page-alert"></div>'), nifty.contentContainer.prepend(t)), i = t, l.focus && u(0);
            else if ("floating" === l.container) e[l.floating.position] || (e[l.floating.position] = n('<div id="floating-' + l.floating.position + '" class="floating-container"></div>'), nifty.container.append(e[l.floating.position])), i = e[l.floating.position], l.floating.animationIn && r.addClass("in animated " + l.floating.animationIn), l.focus = !1;
            else {
                var o = n(l.container),
                    s = o.children(".panel-alert"),
                    c = o.children(".panel-heading");
                if (!o.length) return a = !1, !1;
                s.length ? i = s : (i = n('<div class="panel-alert"></div>'), c.length ? c.after(i) : o.prepend(i)), l.focus && u(o.offset().top - 30)
            }
            return a = !0, !1
        }()
    }
    if (a) {
        if (i.append(r.html(d)), r.find('[data-dismiss="noty"]').one("click", h), l.closeBtn && r.find(".close").one("click", h), l.timer > 0 && (f = setInterval(h, l.timer)), !l.focus) var p = setInterval(function() {
            r.addClass("in"), clearInterval(p)
        }, 200);
        r.one("transitionend webkitTransitionEnd oTransitionEnd MSTransitionEnd animationend webkitAnimationEnd oAnimationEnd MSAnimationEnd", function(n) {
            "max-height" != n.originalEvent.propertyName && "animationend" != n.type || !a || (l.onShown(), a = !1)
        })
    }
}
