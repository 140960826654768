define('ember-percentages/helpers/percentage', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.percentage = percentage;

  function percentage(pct, opts) {
    opts = opts || {};

    var value = (pct || 0) * 100;
    var numDecimals = opts.decimals || 0;

    return value.toFixed(value % 1 === 0 ? 0 : numDecimals) + '%';
  }

  exports['default'] = _ember['default'].Helper.helper(percentage);
});