define('ember-bootstrap-cp-validations/components/bs-form-element', ['exports', 'ember', 'ember-bootstrap/components/bs-form-element'], function (exports, _ember, _emberBootstrapComponentsBsFormElement) {
  'use strict';

  var computed = _ember['default'].computed;
  var defineProperty = _ember['default'].defineProperty;
  var isBlank = _ember['default'].isBlank;

  exports['default'] = _emberBootstrapComponentsBsFormElement['default'].extend({
    attrValidations: null,
    notValidating: computed.not('isValidating').readOnly(),
    notDisabled: computed.not('disabled').readOnly(),

    // Overwrite
    hasValidator: computed.notEmpty('attrValidations').readOnly(),
    hasErrors: computed.and('attrValidations.isInvalid', 'notValidating').readOnly(),
    isValidating: computed.readOnly('attrValidations.isValidating'),
    required: computed.and('attrValidations.options.presence.presence', 'notDisabled'),

    setupValidations: function setupValidations() {
      if (isBlank(this.get("attrValidations"))) {
        defineProperty(this, 'attrValidations', computed.readOnly('model.validations.attrs.' + this.get('property')));
      }

      defineProperty(this, 'errors', computed.readOnly('attrValidations.messages'));
      defineProperty(this, 'warnings', computed.readOnly('attrValidations.warningMessages'));
    }
  });
});