define('ember-x-editable/components/x-base/component', ['exports'], function (exports) {
  'use strict';

  exports['default'] = Ember.Component.extend({
    tagName: 'span',
    classNames: ['x-base'],
    errorMessage: false,
    isEditing: false,
    mouseInsideComponent: false,
    originalValue: null,
    isValid: Ember.computed('errorMessage', function () {
      return !Ember.get(this, 'errorMessage') ? true : false;
    }),

    /**
     * Sets the isFieldEditing property to the current isEditing status.
     * This is used to pass isEditing out to the controller, if you need it
     * @private
     */
    setFieldIsEditing: Ember.observer('isEditing', function () {
      Ember.set(this, 'isFieldEditing', Ember.get(this, 'isEditing'));
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      Ember.run.later(function () {
        var afterRenderLogic = function afterRenderLogic() {
          // TODO fix this empty text handling
          // this.handleEmptyTextValue();
          // Store the original value, so we can restore it on cancel click
          Ember.set(_this, 'originalValue', Ember.get(_this, 'value'));

          if (Ember.get(_this, 'value')) {
            _this.changeUnderlineSize();
          }

          var textarea = _this.element.querySelector('textarea');
          if (textarea) {
            textarea.style.height = '1px';
            textarea.style.height = textarea.scrollHeight + 'px';
          }
        };

        // If custom font families are being loaded with @font-face,
        // we need to wait until the font is loaded to display the inputs
        if (Ember.get(_this, 'fontFamilyConfig')) {
          WebFont.load({
            custom: {
              families: Ember.get(_this, 'fontFamilyConfig')
            },
            active: afterRenderLogic
          });
        } else {
          afterRenderLogic();
        }
      });
    },

    actions: {
      cancelAction: function cancelAction() {
        Ember.set(this, 'isEditing', false);
        Ember.set(this, 'value', Ember.get(this, 'originalValue'));
        Ember.set(this, 'errorMessage', false);
        this.sendAction('cancelAction');
      },
      saveAction: function saveAction() {
        var validator = Ember.get(this, 'validator');
        // Do any validation here, before saving
        if (validator) {
          Ember.set(this, 'errorMessage', Ember.get(this, 'validator')(Ember.get(this, 'value')));

          // If no errors, update the originalValue to be the newly saved value
          if (!Ember.get(this, 'errorMessage')) {
            Ember.set(this, 'originalValue', Ember.get(this, 'value'));
          }
        } else {
          Ember.set(this, 'originalValue', Ember.get(this, 'value'));
        }
        this.saveNewValue();
      }
    },

    focusIn: function focusIn() {
      if (Ember.get(this, 'value') === 'Empty') {
        Ember.set(this, 'value', '');
      }
      Ember.set(this, 'isValid', true);
      Ember.set(this, 'isEditing', true);
    },
    focusOut: function focusOut() {
      if (!Ember.get(this, 'mouseInsideComponent')) {
        this.send('cancelAction');
      }
    },
    mouseEnter: function mouseEnter() {
      Ember.set(this, 'mouseInsideComponent', true);
    },
    mouseLeave: function mouseLeave() {
      Ember.set(this, 'mouseInsideComponent', false);
    },

    /**
     * Calculate the width of a text string, given the element to grab styles from and the text string
     * @param element The element the text is inside, this is used to get font size, weight, etc
     * @param text The text string we are measuring
     * @returns {*}
     * @private
     */
    getTextWidth: function getTextWidth(element, text) {
      var styles = getComputedStyle(element);
      var fontFamily = styles['font-family'];
      var fontSize = styles['font-size'];
      var fontWeight = styles['font-weight'];
      return calculateSize(text, {
        font: fontFamily,
        fontSize: fontSize,
        fontWeight: fontWeight
      });
    },
    saveNewValue: function saveNewValue() {
      // If no errors, go ahead and save
      if (!Ember.get(this, 'errorMessage')) {
        Ember.set(this, 'isEditing', false);
        this.changeUnderlineSize();
        this.sendAction('saveAction');
      }
    }
  });
});