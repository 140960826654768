define("ember-format-currency/helpers/format-currency", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  var _slicedToArray = (function () {
    function sliceIterator(arr, i) {
      var _arr = [];var _n = true;var _d = false;var _e = undefined;try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;_e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }return _arr;
    }return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  })();

  exports.formatCurrency = formatCurrency;

  function formatCurrency(_ref, namedArgs) {
    var _ref2 = _slicedToArray(_ref, 4);

    var quantity = _ref2[0];
    var decimals = _ref2[1];
    var sign = _ref2[2];
    var separator = _ref2[3];

    sign = sign !== undefined ? sign : namedArgs.sign !== undefined ? namedArgs.sign : "$";
    decimals = decimals !== undefined ? decimals : namedArgs.decimals !== undefined ? namedArgs.decimals : 2;
    separator = separator !== undefined ? separator : namedArgs.separator !== undefined ? namedArgs.separator : ", ";

    var amount = parseFloat(quantity);
    var amount_rounded = amount.toFixed(decimals);
    var decimal_part = amount_rounded.toString().split('.')[1];
    var number_part = amount_rounded.toString().split('.')[0];
    var number = number_part.replace(/\B(?=(\d{3})+(?!\d))/g, separator);
    return sign.toString() + " " + number + "." + decimal_part;
  }

  exports["default"] = _ember["default"].Helper.helper(formatCurrency);
});