define('ember-inline-edit/helpers/in-arr', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  exports.inArr = inArr;

  function inArr(params /*, hash*/) {
    var haystack = params[0];
    var needle = params[1];

    if (_ember['default'].isEmpty(haystack) || _ember['default'].isEmpty(needle)) {
      _ember['default'].warn('in-arr params cannot be empty');
      return;
    }

    return haystack.indexOf(needle) > -1;
  }

  exports['default'] = _ember['default'].Helper.helper(inArr);
});