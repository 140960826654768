define('ember-i18n-cp-validations/validators/messages', ['exports', 'ember-cp-validations/validators/messages'], function (exports, _emberCpValidationsValidatorsMessages) {
  'use strict';

  var Handlebars = Ember.Handlebars;

  function isSafeString(input) {
    return typeof Ember.String.isHTMLSafe === 'function' ? Ember.String.isHTMLSafe(input) : input instanceof Handlebars.SafeString;
  }

  function unwrap(input) {
    if (isSafeString(input)) {
      return input.toString();
    }

    return input;
  }

  function emitWarning(msg, meta, ENV) {
    if (!Ember.get(ENV, 'i18n.suppressWarnings')) {
      false && Ember.warn(msg, false, meta);
    }
  }

  exports['default'] = _emberCpValidationsValidatorsMessages['default'].extend({
    i18n: Ember.inject.service(),
    _regex: /\{\{(\w+)\}\}|\{(\w+)\}/g,

    _prefix: Ember.computed('prefix', function () {
      var prefix = Ember.get(this, 'prefix');

      if (typeof prefix === 'string') {
        if (prefix.length) {
          return prefix + '.';
        }

        return prefix;
      }

      return 'errors.';
    }),

    init: function init() {
      this._super.apply(this, arguments);

      this._config = Ember.getOwner(this).resolveRegistration('config:environment') || {};
    },
    getDescriptionFor: function getDescriptionFor(attribute) {
      var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var prefix = Ember.get(this, '_prefix');
      var key = prefix + 'description';
      var setDescriptionKey = void 0;

      if (!Ember.isEmpty(context.descriptionKey)) {
        key = context.descriptionKey;
        setDescriptionKey = true;
      } else if (!Ember.isEmpty(context.description)) {
        return context.description;
      }

      var i18n = Ember.get(this, 'i18n');

      if (i18n && i18n.exists(key)) {
        return unwrap(i18n.t(key, context));
      }

      if (setDescriptionKey) {
        emitWarning('Custom descriptionKey ' + key + ' provided but does not exist in i18n translations.', {
          id: 'ember-i18n-cp-validations-missing-description-key'
        }, this._config);
      }

      return this._super.apply(this, arguments);
    },
    getMessageFor: function getMessageFor(type) {
      var context = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      var i18n = Ember.get(this, 'i18n');
      var prefix = Ember.get(this, '_prefix');
      var key = Ember.isPresent(context.messageKey) ? context.messageKey : '' + prefix + type;

      if (i18n && i18n.exists(key)) {
        return unwrap(i18n.t(key, context));
      }

      emitWarning('[ember-i18n-cp-validations] Missing translation for validation key: ' + key + '\nhttp://offirgolan.github.io/ember-cp-validations/docs/messages/index.html', {
        id: 'ember-i18n-cp-validations-missing-translation'
      }, this._config);

      return this._super.apply(this, arguments);
    },
    formatMessage: function formatMessage(message, context) {
      var m = message;

      if (Ember.isNone(m) || typeof m !== 'string') {
        m = Ember.get(this, 'invalid');
      }

      return m.replace(Ember.get(this, '_regex'), function (s, p1, p2) {
        return Ember.get(context, p1 || p2);
      });
    }
  });
});