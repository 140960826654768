define("ember-x-editable/components/ember-x-editable/template", ["exports"], function (exports) {
  "use strict";

  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.3",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 9,
            "column": 0
          }
        },
        "moduleName": "modules/ember-x-editable/components/ember-x-editable/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "class", "control-group");
        var el2 = dom.createTextNode("\n  ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("div");
        dom.setAttribute(el2, "class", "form-inline editableform");
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        var el3 = dom.createComment("");
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n  ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0, 1]), 1, 1);
        return morphs;
      },
      statements: [["inline", "yield", [["subexpr", "hash", [], ["select", ["subexpr", "component", ["x-editable-select"], ["content", ["subexpr", "@mut", [["get", "content", ["loc", [null, [4, 60], [4, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "validator", ["subexpr", "@mut", [["get", "validator", ["loc", [null, [4, 78], [4, 87]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [4, 94], [4, 99]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 21], [4, 100]]], 0, 0], "text", ["subexpr", "component", ["x-editable-text"], ["validator", ["subexpr", "@mut", [["get", "validator", ["loc", [null, [5, 58], [5, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [5, 74], [5, 79]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 19], [5, 80]]], 0, 0], "textarea", ["subexpr", "component", ["x-editable-textarea"], ["validator", ["subexpr", "@mut", [["get", "validator", ["loc", [null, [6, 66], [6, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "value", ["loc", [null, [6, 82], [6, 87]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 23], [6, 88]]], 0, 0]], ["loc", [null, [3, 12], [6, 89]]], 0, 0]], [], ["loc", [null, [3, 4], [6, 91]]], 0, 0]],
      locals: [],
      templates: []
    };
  })());
});