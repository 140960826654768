define('ember-rapid-forms/components/em-checkbox', ['exports', 'ember', 'ember-rapid-forms/components/em-form-group'], function (exports, _ember, _emberRapidFormsComponentsEmFormGroup) {
  'use strict';

  /*
  Form Input
  
  Syntax:
  {{em-checkbox property="property name"}}
   */
  exports['default'] = _emberRapidFormsComponentsEmFormGroup['default'].extend({
    validationIcons: false,
    validations: false,
    yieldInLabel: true,
    htmlComponent: 'erf-html-checkbox',
    elementClass: null,
    required: null,
    autofocus: null,
    disabled: null,
    wrapperClass: _ember['default'].computed('form.formLayout', {
      get: function get() {
        if (this.get('form.formLayout') === 'horizontal') {
          return 'col-sm-offset-2 col-sm-10';
        }
      }
    }),
    labelWrapperClass: _ember['default'].computed('form.formLayout', {
      get: function get() {
        if (this.get('form.formLayout') === 'horizontal') {
          return 'checkbox';
        }
        return null;
      }
    }),
    "class": _ember['default'].computed('form.formLayout', {
      get: function get() {
        if (this.get('form.formLayout') !== 'horizontal') {
          return 'checkbox';
        }
        return 'form-group';
      }
    })
  });
});