define('ember-bootstrap-cp-validations/components/bs-form', ['exports', 'ember', 'ember-bootstrap/components/bs-form'], function (exports, _ember, _emberBootstrapComponentsBsForm) {
  'use strict';

  var computed = _ember['default'].computed;

  exports['default'] = _emberBootstrapComponentsBsForm['default'].extend({
    hasValidator: computed.notEmpty('model.validate'),

    validate: function validate(model) {
      return new _ember['default'].RSVP.Promise(function (resolve, reject) {
        var m = model;

        if (model instanceof _ember['default'].ObjectProxy) {
          m = model.get('content');
        }

        m.validate().then(function () {
          return model.get('validations.isTruelyValid') ? resolve() : reject();
        }, reject);
      });
    }
  });
});