define('ember-rapid-forms/components/html-checkbox', ['exports', 'ember', 'ember-rapid-forms/templates/components/html-checkbox'], function (exports, _ember, _emberRapidFormsTemplatesComponentsHtmlCheckbox) {
  'use strict';

  exports['default'] = _ember['default'].Component.extend({
    layout: _emberRapidFormsTemplatesComponentsHtmlCheckbox['default'],

    checked: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() /*attrs*/{
      this._super.apply(this, arguments);
      // set it to the correct value of the selection
      this.checked = _ember['default'].computed('mainComponent.model.' + this.get('mainComponent.property'), function () {
        return this.get('mainComponent.model.' + this.get('mainComponent.property'));
      });
    },
    actions: {
      change: function change() {
        var selectedEl = this.$('input')[0];
        var checked = selectedEl.checked;
        this.set('mainComponent.model.' + this.get('mainComponent.property'), checked);
        var changeAction = this.get('action');
        if (changeAction) {
          changeAction(checked);
        }
      },
      input: function input() {
        // input is always called when input is altered
        // except in IE9 where when cutting or removing things it doesn't get fired
        // https://developer.mozilla.org/en-US/docs/Web/Events/input#Browser_compatibility
        var selectedEl = this.$('input')[0];
        var checked = selectedEl.checked;
        this.set('mainComponent.model.' + this.get('mainComponent.property'), checked);
        var changeAction = this.get('action');
        if (changeAction) {
          changeAction(checked);
        }
      }
    }
  });
});