define('ember-sweetalert/components/sweet-alert', ['exports', 'ember', 'ember-sweetalert/index'], function (exports, _ember, _emberSweetalertIndex) {
  'use strict';

  var Component = _ember['default'].Component;
  var on = _ember['default'].on;

  exports['default'] = Component.extend({
    show: true,
    message: '',
    title: '',
    type: '',
    callback: function callback() {},

    setupSweetAlert: on('didInsertElement', function () {
      this._displaySweetAlert();
    }),

    updateAttrs: on('didUpdateAttrs', function () {
      this._displaySweetAlert();
    }),

    teardownSweetAlert: on('willDestroyElement', function () {}),

    _displaySweetAlert: function _displaySweetAlert() {
      var _this = this;

      if (this.get('show')) {
        (0, _emberSweetalertIndex['default'])(this.get('title'), this.get('message'), this.get('type')).then(function (confirm) {
          var cb = _this.get('callback');
          cb(confirm);
          _this.set('show', false);
        });
      }
    }
  });
});