define('ember-rapid-forms/components/em-select', ['exports', 'ember', 'ember-rapid-forms/components/em-form-group'], function (exports, _ember, _emberRapidFormsComponentsEmFormGroup) {
  'use strict';

  /*
  Form Select
  
  Syntax:
  {{em-select property="property name"
      content=array_of_options
      optionValuePath=keyForValue
      optionLabelPath=keyForLabel
      prompt="Optional default prompt"}}
  
      //Optional params
      @param propertyIsModel - (boolean) forces the selected object to be assigned to the property instead of the optionValuePath
   */
  exports['default'] = _emberRapidFormsComponentsEmFormGroup['default'].extend({
    validationIcons: false,
    htmlComponent: 'erf-html-select',
    propertyIsModel: false,
    property: null,
    content: null,
    elementClass: null,
    selection: null,
    optionValuePath: 'id',
    optionLabelPath: 'value',
    prompt: null,
    controlWrapper: _ember['default'].computed('form.formLayout', {
      get: function get() {
        if (this.get('form.formLayout') === 'horizontal') {
          return 'col-sm-10';
        }
        return null;
      }
    })
  });
});